import React from "react";
import { AppointmentType } from "enums";
import { useSearchParams } from "../../hooks";
import { YxBookingFlow } from "./YxBookingFlow";
import { D1BookingFlow } from "./D1BookingFlow";

export interface IBookingContext {
  isRebooking: boolean;
}

export const BookingContext = React.createContext<IBookingContext>({
  isRebooking: false,
});

const BookingFlow = ({
  appointmentType,
}: {
  appointmentType: AppointmentType;
}): JSX.Element => {
  switch (appointmentType) {
    case AppointmentType.Y1:
    case AppointmentType.Y2:
      return <YxBookingFlow appointmentType={appointmentType} />;
    default:
      return <D1BookingFlow />;
  }
};

export const Booking = (): JSX.Element => {
  const { appointmentType = AppointmentType.D1, isRebooking = false } =
    useSearchParams<{
      appointmentType?: AppointmentType;
      from?: string;
      to?: string;
      isRebooking?: boolean;
    }>();

  return (
    <BookingContext.Provider value={{ isRebooking }}>
      <BookingFlow appointmentType={appointmentType} />
    </BookingContext.Provider>
  );
};
