import { IInterpreterLanguage } from "../interfaces";

const interpreterLanguagesEn: Record<IInterpreterLanguage, string> = {
  ALBANIAN: "Albanian",
  AMHARIC: "Amharic",
  ARABIC: "Arabic",
  BENGALI: "Bengali",
  BRAZILIAN_PORTUGUESE: "Brazilian Portuguese",
  BRITISH_SIGN_LANGUAGE: "British Sign Language",
  BULGARIAN: "Bulgarian",
  CANTONESE: "Cantonese",
  CROATIAN: "Croatian",
  CZECH: "Czech",
  DARI: "Dari",
  DUTCH: "Dutch",
  EGYPTIAN_ARABIC: "Egyptian Arabic",
  ESTONIAN: "Estonian",
  FARSI: "Farsi",
  FARSI_IRAN: "Farsi (Iran)",
  FRENCH: "French",
  GERMAN: "German",
  GREEK: "Greek",
  GUJARATI: "Gujarati",
  HEBREW: "Hebrew",
  HINDI: "Hindi",
  HUNGARIAN: "Hungarian",
  ITALIAN: "Italian",
  JAPANESE: "Japanese",
  KASHMIRI: "Kashmiri",
  KOREAN: "Korean",
  KURDISH: "Kurdish",
  LATVIAN: "Latvian",
  LITHUANIAN: "Lithuanian",
  MALAYALAM: "Malayalam",
  MALTESE: "Maltese",
  MANDARIN: "Mandarin",
  NEPALI: "Nepali",
  PASHTO: "Pashto",
  PERSIAN_FARSI: "Persian Farsi",
  POLISH: "Polish",
  PORTUGUESE: "Portuguese",
  PUNJABI: "Punjabi",
  ROMANIAN: "Romanian",
  RUSSIAN: "Russian",
  SERBIAN: "Serbian",
  SERBIAN_SLOVAK: "Serbian Slovak",
  SINHALA: "Sinhala",
  SLOVAK: "Slovak",
  SOMALI: "Somali",
  SPANISH: "Spanish",
  SWAHILI: "Swahili",
  SYLHETI: "Sylheti",
  TAMIL: "Tamil",
  THAI: "Thai",
  TIGRINYA: "Tigrinya",
  TURKISH: "Turkish",
  TWI: "Twi (Ghanaian)",
  UKRAINIAN: "Ukrainian",
  URDU: "Urdu",
  VIETNAMESE: "Vietnamese",
};

const monthsEn = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

export const resources = {
  en: {
    translation: {
      application: "Galleri",
      version: "Version: {{ version }}",
      warning:
        "WARNING: this is a {{ disallowed }} server. Do not enter real data!",
      request: {
        failure: "Request failure",
      },
      contact: {
        phoneNumber: "0800 030 9245",
        email: "participant_help@nhs-galleri.org",
      },
      branding: {
        logos: {
          cptu: "King's College London - Cancer Prevention Trials Unit logo",
          grail: "Grail logo",
          nhs: "NHS Galleri Trial logo",
        },
      },
      navigation: {
        search: "Search",
        register: "Register",
        checkIn: "Check In",
        units: "Manage Units",
        report: "Generate Reports",
        results: "View Results",
        user: "Users",
        y2Booking: "Y2 Booking",
        audit: "Audit Log",
        userEvents: "User Events",
      },
      stages: {
        D1_ELIGIBILITY: "Eligibility",
        D1_ELIGIBILITY_RECHECK: "Eligibility",
        D1_VIDEO: "Video",
        D1_CONSENT: "Consent",
        CREATE_PARTICIPANT: "EDC Enrolment",
        BLOOD_DRAW: "Blood Draw",
        VOUCHER: "Voucher",
        D1_QUESTIONNAIRE: "Questionnaire",
        D1_BLOOD_DRAW: "Blood Draw",
        D1_TRF_CLINICAL_DATA: "TRF Clinical Data",
        Y1_BLOOD_DRAW: "Blood Draw",
        Y1_TRF_CLINICAL_DATA: "TRF Clinical Data",
        Y1_QUESTIONNAIRE: "Questionnaire",
        Y2_BLOOD_DRAW: "Blood Draw",
        Y2_TRF_CLINICAL_DATA: "TRF Clinical Data",
        Y2_QUESTIONNAIRE: "Questionnaire",
        CHECKOUT: "Checkout",
        ABORT_APPOINTMENT: "Abort appointment",
      },
      appointmentStatus: {
        BOOKED: "Booked",
        CANCELLED: "Cancelled",
        ATTENDED: "Attended",
      },
      visitStatus: {
        IN_PROGRESS: "In progress",
        COMPLETED: "Completed",
        ABORTED: "Aborted",
      },
      interpreterLanguages: interpreterLanguagesEn,
      months: monthsEn,
      errors: {
        unknown: "Unknown error please try again.",
        400: "The request cannot be processed.",
        401: "Your authentication is incorrect. Please try again.",
        403: "You are not authorised to complete this action.",
        404: "The record you requested cannot be found.",
        409: "You cannot create a duplicate of this record.",
        422: "The request cannot be processed.",
        500: "There's been a server error. Please contact the call centre for help.",
        edc: {
          400: "There was a problem submitting to the EDC system. {{- message}}. Please correct and resubmit.",
          500: "There was a problem connecting to the EDC system. Please reach out to nhs-galleri-tech-helpdesk@grailbio.com",
        },
        submission:
          "There was a problem submitting the form: {{message}}. Please correct and resubmit.",
        registration: {
          unauthorised: "You are not authorised to register",
          alreadyRegistered:
            "To complete your registration please call freephone 0800 030 9245 between 8am to 7pm Monday to Friday (excluding bank holidays).",
        },
        participantAuth: {
          unrecognisedInvitationCode:
            "Invalid invitation code. Please make sure the entered invitation code is correct and try again.",
        },
        submissionErrorTitle: "There was a problem with your submission",
      },
      components: {
        login: {
          signOut: "Sign Out",
        },
        formValidity: {
          summaryTitle: "There is a problem",
        },
        appointmentTimeSlot: {
          available: "{{ available }} available",
        },
        accessibilityRequirements: {
          wheelchair: "Wheelchair access",
          parking: "Disabled car parking",
          stepFree: "Step-free access",
          interpreter: "Interpreter services",
          visual: "Sighted or visual assistance",
          hearing: "Hearing assistance",
          signLanguage: "Sign language services",
          none: "None",
        },
        backToTop: {
          label: "Back to top",
        },
        search: {
          heading: "Find a Participant",
          searchBox: {
            label: "Search box",
            placeholder: "Search",
          },
          searchInstructions:
            "Search by cohort ID, participant ID, name, or NHS number ",
          searchMenu: {
            label: "Search menu",
            placeholder: "All",
            options: {
              cohortId: "Cohort ID",
              participantId: "Participant ID",
              nhsNumber: "NHS number",
              name: "Name",
            },
          },
          button: {
            label: "Search",
          },
        },
        gp: {
          find: "Find address",
          other: {
            label: "Other",
            hint: "Please select if your GP is not displayed here",
          },
          errors: {
            title: "Error retrieving GP information",
            empty:
              "GP not found for this postcode. Please change the GP postcode.",
            emptyOtherAllowed:
              "GP not found for this postcode. Please change the GP postcode or enter your GP below.",
            other:
              "There was an error finding GPs, please enter your GP below.",
            findingGPs: "There was an error finding GPs, please try again.",
          },
        },
        findMyNHSNumber: {
          title: "Where can I find my NHS number?",
          documents: {
            body: "You can find your NHS number in the NHS app or on any document the NHS has sent you, such as your:",
            items: {
              prescriptions: "prescriptions",
              testResults: "test results",
              hospitalReferralLetters: "hospital referral letters",
              appointmentLetters: "appointment letters",
              yourNHSMedicalCard: "your NHS medical card",
            },
          },
          link: {
            body: "If you do not know your NHS number you can be sent it using the NHS",
            action: "online service (opens in new window)",
          },
        },
        person: {
          edit: {
            title: "Editing {{ name }}",
            success: "Your changes have been saved",
            actions: {
              cancel: "Cancel changes",
              submit: "Save changes",
            },
          },
          labels: {
            name: "Name",
            firstName: "First name",
            lastName: "Last name",
            nhsNumber: "NHS Number",
            cohortId: "Cohort ID",
            studyId: "Participant ID",
            change: "Change",
            dateOfBirth: "Date of birth",
            age: "Age",
            address: "Address",
            gpAddress: "GP",
            gpPracticeName: "GP Practice",
            gpName: "GP Name",
            contactDetails: {
              label: "Contact details",
              fields: {
                phoneNumber: "Main phone number",
                mobilePhoneNumber: "Mobile phone number",
                email: "Email address",
              },
            },
            dataSharingAgreementVersion: "Data sharing agreement version",
            eligibilityStatus: "Eligible",
            studyStatus: {
              label: "Participant status",
              active: "Active",
              withdrawn: "Withdrawn",
            },
            outsideOfEngland: "Outside of England",
            y1Anniversary: "Y1 Anniversary date",
            y2Anniversary: "Y2 Anniversary date",
          },
          eligibility: {
            eligible: "Yes",
            ineligible: "No",
            noSurvey: "Check Eligibility",
          },
          contactStatus: {
            read: {
              title: "Notifications",
              edit: "Manage",
            },
            edit: {
              title: "Manage Notifications",
              enabled: "Enabled",
              optOut: "Opt out",
              save: "Save",
              cancel: "Cancel",
            },
            email: "Email Notifications",
            sms: "SMS Notifications",
            optOut: "Opted out",
            enabled: "Enabled",
          },
          blood: {
            title: "Blood Draw Status",
            reAttempt: {
              add: "Mark re-attempt",
              needed: "Re-attempt needed",
              remove: "Remove re-attempt",
              error: {
                title: "Error in re-attempt",
                bloodFormNotComplete:
                  "Cannot mark re-attempt as most recent blood form has not been completed. Complete existing appointment.",
                reAttemptNotNeeded:
                  "Cannot mark re-attempt as most recent blood form indicates a successful blood draw.",
              },
            },
            reDraw: {
              add: "Mark re-draw",
              needed: "Re-draw needed",
              remove: "Remove re-draw",
              error: {
                title: "Error in re-draw",
                add: {
                  notCompletedAppointment:
                    "Cannot mark re-draw as appointment is not completed",
                },
                remove: {
                  notCompletedAppointment:
                    "Cannot remove re-draw as appointment is not completed",
                },
              },
            },
          },
          deleteData: {
            title: "Delete personal data",
            button: "Delete invitee",
          },
          withdrawParticipant: {
            title: "Withdraw the participant",
            withdraw: "Withdraw",
          },
          cancerHistory: {
            title: "Cancer treatment status",
            table: {
              event: "Event",
              user: "By",
              date: "Date",
              markedtrue:
                "Participant was marked as receiving cancer treatment",
              markedfalse:
                "Participant was marked as not receiving cancer treatment",
              markedByNCRAS:
                "This participant has been marked as 'Confirmed NCRAS cancer diagnosis'",
              ncrasUnmarked:
                "NCRAS cancer diagnosis removed (Reason: {{reason}})",
            },
            changeCancerTreatmentStatusButton: "Change cancer treatment status",
            removeNCRASStatusButton: "Remove NCRAS status",
          },
          deceased: {
            title: "Participant deceased status",
            table: {
              event: "Event",
              user: "By",
              date: "Date",
              markedTrue: "This participant has been marked as 'Deceased'",
            },
          },
          studyEndpoint: {
            title: "Study end point",
            markOutsideOfEngland: "Outside of England",
            removeOutsideOfEngland: "Remove outside of England",
            markNotParticipating: "Mark as not participating in blood draws",
            removeNotParticipating: "Remove not participating in blood draws",
            markSelfReportedCancer: "Mark as having self reported cancer",
            removeSelfReportedCancer: "Remove self reported cancer marking",
            error: "Error updating outside of England",
          },
          studyEndpointHistory: {
            table: {
              event: "Event",
              user: "By",
              date: "Date",
              markedtrue:
                "Participant was marked as participating in blood draws",
              markedfalse:
                "Participant was marked as no longer participating in blood draws",
              empty: "No events",
              reportedtrue:
                "Participant was marked as having self reported cancer",
              reportedfalse:
                "Participant self reported cancer marking was removed",
            },
          },
          studyEvents: {
            title: "Study Events",
            canBook: "Can book from {{ from }} to {{ to }}",
            errors: {
              loadStudyEvents: "Error loading study events",
            },
          },
          errors: {
            getEligibility: {
              title: "Error checking eligibility",
              label: "Eligibility error",
            },
            loadPerson: {
              title: "Error loading person information",
            },
            loadTreatmentHistory: {
              title: "Error loading treatment history",
            },
          },
        },
        gpAddressInput: {
          accordion: {
            title: "Where can I find my GP’s postcode?",
            body: {
              text: "The address of your GP can usually be found on any letter or document they have sent you.",
              link: {
                text: "You can find your GP address using the NHS",
                action: "Find a GP service (opens in new window)",
              },
            },
          },
          fields: {
            address1: {
              label: "Building and street",
              hint: "",
              errors: {
                empty: "You must provide a valid address",
              },
            },
            address2: {
              label: "Building and street (2 - optional)",
              hint: "",
            },
            county: {
              label: "County (optional)",
              hint: "",
            },
            postcode: {
              label: "Postcode",
              hint: "Enter at least the first part of the postcode like L4, CR0 or CE1B",
              errors: {
                invalid: "You must provide a valid postcode",
              },
            },
            town: {
              label: "Town or City",
              hint: "",
              errors: {
                empty: "You must provide a valid town or city",
              },
            },
          },
        },
        addressInput: {
          fields: {
            address1: {
              label: "Building name or number and street",
              hint: "",
              errors: {
                empty: "Enter the first line of your address",
              },
            },
            address2: {
              label: "Address line 2 (optional)",
              hint: "",
            },
            county: {
              label: "County (optional)",
              hint: "",
            },
            postcode: {
              label: "Postcode",
              hint: "",
              errors: {
                invalid: "Enter a valid postcode",
              },
            },
            town: {
              label: "Town or city",
              hint: "",
              errors: {
                empty: "Enter your town or city",
              },
            },
          },
        },
        postcodeSearchInput: {
          title: "Find a mobile clinic",
          label: "Your postcode",
          hint: "Enter your postcode to find mobile clinics near you",
          postcode: {
            key: "Postcode",
            change: "Change",
          },
          errors: {
            invalid: "You must provide a valid postcode",
          },
          search: "Find mobile clinics",
        },
        supportedBrowser: {
          title: "You are using an out of date browser",
        },
        timeInput: {
          fields: {
            hour: {
              label: "Hour",
            },
            minute: {
              label: "Minute",
            },
          },
          errors: {
            invalid: "Invalid time",
          },
        },
        dateInput: {
          fields: {
            today: "Today ({{date}})",
            other: "Other",
          },
          errors: {
            invalid: "Invalid date",
          },
        },
        dateTimeInput: {
          error: {
            title: "Date / time input error",
          },
          errors: {
            invalid: "Invalid date value",
          },
        },
        callCentreNote: {
          label: "Call Centre Note",
          note: "If you would prefer to register by phone, call freephone 0800 030 9245 from 8am to 7pm, Monday to Friday (excluding bank holidays).",
          note_D1:
            "If you would prefer to register by phone, call freephone 0800 030 9245 from 8am to 7pm, Monday to Friday (excluding bank holidays).",
          note_Y1:
            "If you need help booking online, please call freephone 0800 030 9245 from 8am to 7pm, Monday to Friday (excluding bank holidays).",
          note_Y2:
            "If you need help booking online, please call freephone 0800 030 9245 from 8am to 7pm, Monday to Friday (excluding bank holidays).",
        },
        schedule: {
          actions: {
            publish: "Publish",
            makeInactive: "Make All Inactive",
            saving: "Saving...",
            saveChanges: "Save Changes",
          },
          errors: {
            appointmentsAlreadyBooked:
              "Your changes cannot be saved as there are appointments booked for this time",
            cachingIssue:
              "There is a temporary issue with the caching system, please retry the latest action",
            genericError: "Error saving",
          },
          table: {
            headers: {
              from: "From",
              to: "To",
              capacity: "Capacity",
              status: "Status",
            },
          },
          slots: {
            noSlots: "Address doesn't overlap any slots",
          },
          appointments: {
            heading: "Appointments",
            table: {
              headers: {
                time: "Time",
                cohortID: "Cohort ID",
              },
            },
            noTable: "There are no booked appointments for this date.",
          },
        },
        weekSelector: {
          previous: "Previous week",
          next: "Next week",
        },
        ecrf: {
          placeholders: {
            systemPopulated: "This field will be system populated",
            selectAnAnswer: "Please select an answer",
          },
          actions: {
            submit: "Submit",
            review: "Review",
          },
        },
        emailInput: {
          fields: {
            email: {
              label: "Email address (optional)",
              hint: "If you provide this we can send you reminders when it is time for your appointment.",
            },
          },
        },
        nameInput: {
          fields: {
            firstName: {
              label: "First name",
              hint: "",
            },
            lastName: {
              label: "Last name",
              hint: "",
            },
          },
        },
        personalPhoneNumberInput: {
          fields: {
            phone: {
              label: "Main phone number",
              hint: "We need this if someone from the trial team needs to talk to you.",
            },
            mobilePhone: {
              label: "Mobile phone number (optional)",
              hint: "If the number you have given above is not for a mobile phone, you can also provide one to receive SMS reminders when it is time for your appointment.",
            },
          },
        },
        postalAddressInput: {
          title: "What is your postal address?",
          subheading:
            "We need this to send your appointment letter in the post.",
        },
        biologicalSexInput: {
          fields: {
            biologicalSex: {
              label: "Sex assigned at birth",
              hint: "The sex you were registered with at birth",
              options: {
                male: "Male",
                female: "Female",
              },
            },
          },
        },
        findGPForm: {
          text: "We need to know your GP to confirm if they are located in one of the areas where the trial is being run. Enter the postcode of your GP to get the address.",
          search: {
            label: "Postcode",
            label_D1: "Postcode",
            label_Y1: "GP postcode",
            hint: "Enter at least the first part of the postcode like L4, CR0 or CE1B",
            error: "Enter your GP’s postcode",
          },
          accordion: {
            title: "Where can I find my GP’s postcode?",
            body: {
              text: "The address of your GP can usually be found on any letter or document they have sent you.",
              link: {
                text: "You can find your GP address using the NHS",
                action: "Find a GP service (opens in new window)",
              },
            },
          },
        },
        noGPView: {
          title: "GP not listed",
          paragraph1:
            "We need to know your GP to confirm if they are located in one of the areas where the trial is being run.",
          paragraph2:
            "To complete registration, please call freephone 0800 030 9245 from 8am to 7pm, Monday to Friday (excluding bank holidays), or email $t(contact.email).",
        },
        amendDetailsNoGPView: {
          title: "GP not listed",
          paragraph1:
            "Please contact the call centre to continue your booking by calling freephone 0800 030 9245 from 8am to 7pm, Monday to Friday (excluding bank holidays).",
        },
        selectGPForm: {
          selection: {
            or: "or",
            label: "Select an address",
            noGpsFound: "No GPs found for this postcode",
            error: "Select your GP",
            notListed: "My GP is not listed",
          },
          errors: {
            title: "Error retrieving GP information",
          },
        },
        registrationClosed: {
          paragraph1:
            "Registration for the NHS-Galleri trial is closed - the trial is not accepting new participants at this time.",
          paragraph2:
            "If you are already taking part in the trial, you will receive a reminder letter when it is time to book your 12 month appointment. To find out more about your 12 month appointment, visit https://www.nhs-galleri.org/.",
        },
        textArea: {
          charactersRemaining: "{{charactersLeft}} characters remaining",
        },
        monthSelect: {
          placeholder: "Select Month",
        },
        studyYearSelect: {
          placeholder: "Select Year",
        },
      },
      containers: {
        login: {
          title: "Sign in",
          errors: {
            title: "There is a problem",
            incorrectUsernameOrPassword: "Incorrect username or password",
            unknown: "Unknown error has occured",
          },
          registerMFA: {
            errors: {
              invalidCode: "Invalid code",
              unknown: "Unknown error has occured",
            },
            title: "Register MFA",
            description:
              "Scan the QR code in your authenticator app and enter the code to confirm",
            label: "Confirm code",
            button: {
              label: "Register",
              loading: "Register...",
            },
          },
          code: {
            title: "Enter code",
            SMS: "Enter the code from your SMS",
            TOTP: "Enter the code from your registered device",
            label: "Code",
            button: {
              label: "Submit",
              loading: "Submit...",
            },
            errors: {
              codeMismatch: "Invalid code",
              unknown: "Unknown error has occured",
            },
          },
          updatePassword: {
            title: "Choose a new password",
            password: {
              label: "Password",
            },
            passwordCheck: {
              label: "Confirm password",
            },
            label: "Update password",
            loading: "Update password...",
            errors: {
              different: "Passwords do not match",
              invalidPassword:
                "Password does not meet the minimum requirements. It must be:",
              requirements: {
                length: "must be a minimum of 12 characters in length",
                lowercase: "have a lowercase letter",
                uppercase: "have an uppercase letter",
                number: "have a number",
                symbol: "have a symbol",
              },
              unknown: "Unknown error has occured",
            },
          },
          signIn: {
            label: "Sign in",
            loading: "Sign in...",
          },
          fields: {
            username: {
              label: "Username",
            },
            password: {
              label: "Password",
            },
          },
        },
        wizard: {
          next: "Next",
          previous: "Go back",
        },
        welcomeAuthenticated: {
          title: "Welcome, {{user}}",
          roles: "Please use the menu items to perform tasks.",
          noRoles: "You have not been assigned a role. Please contact KCL.",
          portals: {
            emsUnitStaff: {
              links: {
                search: "Search for a participant",
                register: "Register a new participant",
                checkIn: "Check in a participant",
              },
            },
          },
        },
        withdrawal: {
          cancel: "Cancel",
          error: "Something went wrong. Please try again later.",
          loadingErrorTitle: "Error loading participant status",
          savingFormErrorTitle: "Error updating participant status",
        },
        dataDeletion: {
          title:
            "Once an invitee's data has been deleted this is irreversible. All records of this invitee will be deleted from the system and once completed this action cannot be undone.",
          warning: {
            label: "Are you sure?",
            text: "This action cannot be undone. This will permanently delete this invitee from the system. Please enter 'delete' into the box provided. I acknowledge that I understand this action cannot be undone and that by doing so this persons data will be permanently deleted from the system.",
          },
          button: "Confirm delete",
          error: "Unable to perform data deletion request.",
          confirmation: "Data successfully deleted.",
        },
        cancerTreatmentStatus: {
          title: "Change Cancer Treatment Status",
          warning: {
            label: "Important",
            text: "Changing the cancer treatment status impacts a participant’s ability to participate in the NHS Galleri trial.",
          },
          status: {
            title: "Cancer status",
            marked: "Receiving cancer treatment",
            unmarked: "Not receiving cancer treatment",
          },
          instruction: `<p>To change this status to ‘{{status}}’, please enter ‘<strong>NHS Galleri</strong>’ in the box below (case sensitive) to proceed.</p>`,
          button: "Confirm change",
          error: "Unable to perform cancer treatment status change.",
          confirmation: "Cancer treatment status successfully updated",
        },
        removeNCRASStatus: {
          title: "Remove NCRAS Cancer Diagnosis Status",
          warning: {
            label: "Important",
            text: "This status <strong>should only be removed</strong> if the NCRAS cancer diagnosis has been received in error",
          },
          status: {
            title: "Cancer status",
            marked:
              "The participant has been marked as 'Confirmed NCRAS cancer diagnosis'",
          },
          instruction: `<p>To remove this cancer diagnosis status, please enter ‘<strong>confirm</strong>’ in the box below (case sensitive) to proceed.</p>`,
          button: "Confirm change",
          reason: "Reason (optional)",
          error: {
            title: "Unable to remove NCRAS status",
            detail: "The status may already have been removed.",
          },
          confirmation: "NCRAS Cancer Diagnosis Status successfully removed",
        },
        outsideEngland: {
          title: "Mark Participant as Outside of England",
          warning: {
            label: "Important",
            text: {
              intro:
                "Participants who inform the trial that they have emigrated permanently outside of England are considered lost to follow up.",
              reminders: {
                title: "Please remind the participant:",
                appointments: "Your existing appointments will be cancelled",
                notifications: "You will not receive further notifications",
                letters:
                  "You can ignore any letters that you may receive about booking an appointment",
              },
            },
          },
          instruction: `<p>To confirm this participant is outside of England, please enter ‘<strong>confirm</strong>’ in the box below to proceed</p>`,
          button: "Confirm change",
          error: "Unable to mark participant outside of England",
          confirmation: "Participant successfully marked as Outside of England",
        },
        removeOutsideEngland: {
          title: "Unmark Participant as Outside of England",
          instruction: `<p>To confirm participant is no longer outside of England, please enter ‘<strong>confirm</strong>’ in the box below to proceed</p>`,
          error: "Unable to unmark participant outside of England",
          confirmation: "Participant no longer marked as Outside of England",
        },
        welcome: {
          title: "Register for the NHS-Galleri trial",
          description:
            "Register your details to take part in the trial and book your appointment to attend a mobile clinic. At your appointment, you will be asked to provide consent to take part in the trial.",
          instructions: {
            title: "Before you start",
            description:
              "To complete registration for the trial you will need:",
            items: {
              gpDetails: "your GP details",
              yourNHSNumber: "your NHS number",
              invitationCode: "an invitation code to register",
              dateOfBirth: "your date of birth",
              contactDetails: "your contact details",
              findMyNHSNumber: "Where can I find my NHS number?",
            },
          },
          moreInformation: {
            title: "More information about the trial",
            links: {
              visitOurWebsite: "Visit the NHS-Galleri trial website",
              aboutYourData: "About your data in the trial",
              contactUs: "Contact us at participant_help@nhs-galleri.org",
            },
          },
          action: "Start now",
        },
        acknowledgement: {
          title: "Acknowledgement",
        },
        booking: {
          title: "Book your appointment",
          description:
            "Your appointment should take around {{durationText}}. Seating is provided during your appointment. If you need a carer you can bring them with you on the day.",
          fields: {
            cohortId: {
              label: "Reference number",
            },
            name: {
              label: "Name",
            },
            date: {
              title: "Date of appointment",
              label: "Date",
              hint: "The date you want to book an appointment",
              help: "If the date doesn't work, please try a date after:",
              validation: {
                invalidDate: "You must provide a valid date",
                withinNextDays:
                  "You must provide a date within the next {{rangeMaxDays}} days",
                notLaterThanNWorkingDays:
                  "You must provide a date later than {{nWorkingDays}} working days from today",
              },
              actions: {
                submit: {
                  label: "Find appointments",
                },
                change: {
                  label: "Change",
                },
              },
            },
            region: {
              actions: {
                confirm: {
                  label: "Next",
                  error: "A region must be selected",
                },
              },
              header:
                "Please select a region to view the available appointments.",
              placeholder: "Select a region",
              regions: {
                NHS_01: "Cheshire & Merseyside",
                NHS_02: "SEL & Kent",
                NHS_03: "Greater Manchester",
                NHS_04: "Northern",
                NHS_05: "West Midlands",
                NHS_06: "East Midlands",
                NHS_07: "East Anglia",
              },
            },
            search: {
              updateSearch: "Update Search",
              errors: {
                title: "Error",
                accessibilityNotSelected: "Select at least one option",
                languageNotSelected:
                  "Please choose one of the interpreter languages",
                regionNotSelected: "Please choose a region",
              },
              accessibility: {
                title: "Accessibility at your appointment",
                subheading: "Accessibility requirements",
                legends: {
                  options: "Select any options that are relevant to you",
                  interpreterLanguages: "Language to be translated",
                },
                inputs: {
                  wheelchair: {
                    label: "Wheelchair access",
                    legend:
                      "To search for mobile clinics with a ramp for wheelchair access.",
                  },
                  parking: {
                    label: "Disabled car parking",
                    legend:
                      "To search for mobile units with disabled car parking close to the mobile clinic. Large disabled bays cannot always be guaranteed next to the units, however there is typically plenty of parking and we reserve parking spaces close to the unit for those requiring disabled parking.",
                  },
                  stepFree: {
                    label: "Step-free access",
                    legend:
                      "To search for mobile units with no steps, as some of our smaller units have a few high steps at the entrance. Please note, the mobile clinics do not have step-free access to the toilet facilities.",
                  },
                  interpreter: {
                    label: "Language interpreter services",
                    legend:
                      "As a part of the trial, we require that medical interpreters are used in place of a friend or relative. Certified medical interpreters are trained to translate health information correctly. This service is free. We can also arrange an interpreter over the phone to complete the booking.",
                    placeholder: "Please select",
                  },
                  visual: {
                    label: "Sighted or visual assistance",
                    legend:
                      "If you are registered blind, partially sighted or need any visual assistance, there are measures that can be taken to support your appointment.",
                  },
                  hearing: {
                    label: "Hearing assistance",
                    legend:
                      "If you are hard of hearing or need additional support there are measures that can be taken to support your appointment. For example, the nurse can wear a visor instead of a face mask to make lip reading easier.",
                  },
                  signLanguage: {
                    label: "Sign language services",
                    legend:
                      "As a part of the trial, it is important to be able to communicate with the nurse, we require that medical interpreters are used in place of a friend or relative.  Certified medical interpreters are trained to translate health information correctly between spoken and signed language. This service is free.",
                  },
                  none: {
                    label:
                      "I do not have any of the above accessibility requirements",
                  },
                },
                divider: {
                  label: "Or",
                },
              },
            },
            location: {
              label: "Mobile clinic",
              title: "Choose a mobile clinic",
              subtitle: "Select a mobile clinic near you",
              errors: {
                title: "There is a problem",
                noAvailableLocations:
                  "Thank you for registering for the NHS-Galleri trial. We are sorry there are no appointments in your area at this time. Please contact the call centre on 0800 030 9245 from 8am to 7pm, Monday to Friday (excluding bank holidays) to see if more appointments have become available. You will need to have an appointment in order to take part in the NHS-Galleri trial.",
                failedToLoadSlots:
                  "Unable to load available appointments. Please contact the call centre on 0800 030 9245 from 8am to 7pm, Monday to Friday (excluding bank holidays).",
                locationNotSelected:
                  "Select a mobile clinic convenient for you",
              },
              actions: {
                change: {
                  label: "Change",
                },
              },
              bookingNote: {
                title: "Finding an appointment",
                content:
                  "If you cannot find an appointment that suits you, please call freephone 0800 030 9245 from 8am to 7pm, Monday to Friday (excluding bank holidays). The team may be able to find or arrange for new appointments to be made.",
              },
            },
            slot: {
              label: "Date and time",
              title: "Choose a date and time",
              duration: "{{duration}} mins",
              morningAvailable: "Morning available",
              afternoonAvailable: "Afternoon available",
              heading: {
                showAll: "All available dates",
                showNext5: "Next 5 available dates",
              },
              toggleLink: {
                showAll: "See only next 5 available dates",
                showNext5: "See all available dates",
              },
              errors: {
                title: "There is a problem",
                dateNotSelected: "Select a day",
                timeNotSelected: "Select an appointment time",
              },
              tabs: {
                title: "Appointment Duration",
              },
              actions: {
                change: {
                  label: "Change",
                },
              },
              yxNote: {
                title: "Important",
                firstAppointmentNote: "Your first appointment was {{date}}",
                instruction:
                  "Please book an appointment close to 12 months after your first appointment. This will help the trial by making sure your blood samples are taken about 12 months apart.",
                instruction_Y2:
                  "Please book an appointment close to 24 months after your first appointment. This will help the trial by making sure your first and last blood samples are about 24 months apart.",
              },
            },
          },
          confirmNote: {
            title: "Important",
            content:
              "Please review your appointment details and select 'Book appointment' to confirm your booking.",
          },
          noSlots: "No appointments available.",
          noSlotsForDuration:
            "There are no appointments of {{ duration }} mins length. Please select a different appointment length in order to continue your booking.",
          noSlotsForWeek:
            "No appointments available for this week. Please try another week.",
          viewAppointments: "View Appointments ({{ number }})",
          noAppointments: "No appointment available for this date",
          noDateSelected: "Select a date above to search for an appointment",
          actions: {
            wizard: {
              continue: "Continue",
            },
            findUnitLocations: {
              error: "Failed to find location. Please try later.",
            },
            findAppointmentAvailability: {
              error:
                "Failed to find appointment availability. Please try later.",
            },
            book: {
              title: "Confirm your appointment",
              label: "Book appointment",
              errors: {
                slotAlreadyBooked:
                  "The appointment slot is no longer available. Please select another slot or change date or location.",
                appointmentAlreadyExist:
                  "You already have an appointment booked. Cannot book another appointment.",
                unknown:
                  "Something went wrong. Please contact us by calling {{number}}.",
              },
              loading: "Booking ...",
            },
            rebook: {
              title: "Book your new appointment",
              label: "Book new appointment",
              footnote:
                "If you choose to book a new appointment, your old appointment will be canceled",
              errors: {
                noAppointmentToRebook:
                  "Could not rebook appointment. Please contact us by calling {{number}}.",
              },
            },
          },
          accessibilitySummary: {
            label: "Accessibility requirements",
            options: {
              none: "You do not have any accessibility requirements",
            },
            actions: {
              change: {
                label: "Change",
              },
            },
          },
          callCentreHandlingAccessibility: {
            title: "Please call to complete your booking",
            title_D1: "Please call to complete your booking",
            title_Y1: "Please call to book",
            message: `
              <p>Appointments that require language services cannot be booked online.</p>
              <p>To complete your appointment booking, please call freephone $t(contact.phoneNumber) from 8am to 7pm, Monday to Friday (excluding bank holidays), or email $t(contact.email) quoting the reference number <strong>{{cohortId}}</strong>.</p>
              <p>You do not need to speak English. The call centre will be able to provide an interpreter to help you with your call.</p>
            `,
          },
        },
        bookingConfirmation: {
          title: "Appointment booked",
          yx: {
            thankYou:
              "Thank you for booking your appointment, we look forward to seeing you soon.",
            reminders: {
              heading: "Reminders about your appointment",
              line1:
                "You will be sent an appointment booking letter in the post.",
              line2:
                "If you have shared a mobile phone number and/or email address you will be sent an email and/or SMS reminder before your appointment.",
              line3:
                "To opt out of these reminders, please call freephone 0800 030 9245 from 8am to 7pm, Monday to Friday (excluding bank holidays).",
            },
            info: {
              heading: "What happens at your appointment",
              line1: "Your appointment should last around 15 minutes.",
              line2: "This will include:",
              list1: {
                line1: "Being checked-in using the details you have provided;",
                line2: "Giving a blood sample; and",
                line3: "Answering a short survey about your health.",
              },
              line3:
                "Before your appointment, you may like to remind yourself about the trial by:",
              list2: {
                line1Start:
                  "Watching a short animation about the trial (please visit ",
                link1: "https://www.nhs-galleri.org/animation",
                line1End: ")",
                line2Start:
                  "Reading your Participant Information Sheet (available at ",
                link2: "https://www.nhs-galleri.org/info-sheet",
                line2End: ")",
              },
              line4: "More information about the trial can be found at ",
              link: "https://www.nhs-galleri.org",
            },
            changing: {
              heading: "Changing your appointment",
              line1:
                "If you are no longer able to attend this appointment you may be able to book another appointment at a different date or time.",
              action: "Book a new appointment",
              cancelling: {
                heading: "Canceling your appointment",
                line1start:
                  "If you are no longer able to attend and do not want to book a new appointment, please contact the trial team at ",
                email: "participant_help@nhs-galleri.org",
                line1end:
                  " or call freephone 0800 030 9245 from 8am to 7pm, Monday to Friday (excluding bank holidays).",
              },
            },
            covid: {
              heading: "COVID-19 information",
              line1:
                "Please do not attend your appointment if you or a member of your household have tested positive for COVID-19 or have any symptoms.",
            },
          },
          fields: {
            name: {
              label: "Name",
            },
            reference: {
              label: "Reference number",
            },
            dateAndTime: {
              label: "Date and Time",
            },
            address: {
              label: "Mobile clinic",
            },
            unitLocationDirections: {
              label: "Getting to your appointment",
            },
            cohortId: {
              label: "Reference",
            },
            slot: {
              label: "Date and Time",
            },
          },
          errorSummary: {
            title: "Something went wrong",
            description: "No appointment to confirm.",
            actions: {
              goToMain: "Go back to the main page",
            },
          },
          messages: {
            title: "Appointment letter",
            success:
              "You will receive your appointment booking letter in the post. Please bring this with you on the day of your appointment.",
            participantInformationSheet:
              "You will also receive a participant information sheet that has further information about the NHS-Galleri trial. Please read this ahead of your appointment. You can also view this online:",
            participantInformationSheetLink:
              "Download participant information sheet",
            participantInformationSheetCopy:
              "A copy of the informed consent form will also be included if you wish to read this ahead of your appointment.",
            consentFormCompletion:
              "You do not need to complete your consent form before your visit as the nurse will ask you to do this at your appointment.",
            bringAppointmentLetter:
              "Please remember to bring your appointment letter with you on the day of your appointment",
            whatHappensOnTheDay:
              "You need to go to the mobile clinic you have booked for your appointment. Please get to your appointment at the arrival time you selected.",
          },
          appointmentLetter: {
            title: "Appointment letter",
          },
          onTheDay: {
            title: "What happens on the day",
            description:
              "You need to go to the mobile clinic you have booked for your appointment. Please get to your appointment at the arrival time you selected.",
            items: {
              title: "You will need to bring:",
              whatYouNeedToBring: "You will need to bring:",
              yourAppointmentLetter: "Your appointment letter",
              aFaceCovering:
                "a face covering, unless you cannot wear one for health or disability reasons",
            },
          },
          yourAppointment: {
            title: "What happens at your appointment",
            description:
              "Your appointment should take around {{durationMin}} to {{durationMax}} minutes, and will include:",
            items: {
              beingCheckedIn:
                "check-in using the details you provided when you registered for your appointment",
              answeringQuestions:
                "answering questions on whether you are able to take part in the trial",
              givingBlood: "giving a blood sample",
              answeringSurveys: "answering surveys about you and your health",
            },
          },
          important: {
            title: "Important",
            description:
              "If you think you have symptoms of coronavirus or you have recently been told you need to isolate you should not attend your appointment and will need to book again.",
          },
          existing: {
            title: "Your Appointment",
          },
          changeAppointment: {
            title: "Change your appointment",
            button: "Change appointment",
            description1: `<p>Please give as much notice as possible if you need to change or cancel your appointment.</p>
              <p>If you cannot find an appointment that suits you, please call freephone 0800 030 9245 from 8am to 7pm, Monday to Friday (excluding bank holidays). The team may be able to find or arrange for new appointments to be made.</p>`,
          },
          cancellingYourAppointment: {
            title: "Cancelling your appointment",
            paragraph1:
              "If you are unable to attend your appointment it is important you let us know as soon as possible so the appointment can be made available to another participant.",
            paragraph2:
              "To cancel and rebook your appointment, please call freephone 0800 030 9245 from 8am to 7pm, Monday to Friday (excluding bank holidays).",
          },
          covid: {
            title: "COVID-19 information",
            description:
              "Please do not attend your appointment if you or a member of your household have tested positive for COVID-19 or have the following symptoms:",
            items: {
              highTemperature:
                "A high temperature. This means you feel hot to touch on your chest or back (you do not need to measure your temperature).",
              newCough:
                "A new, continuous cough. This means coughing a lot for more than an hour, or 3 or more coughing episodes in 24 hours (if you usually have a cough, it may be worse than usual).",
              lostOfSmell:
                "A loss or change to your sense of smell or taste. This means you've noticed you cannot smell or taste anything, or things smell or taste different to normal.",
              footer:
                "Most people with coronavirus have at least 1 of these symptoms.",
            },
          },
        },

        checkIn: {
          title: "Appointments scheduled for",
          errors: {
            title: "Unable to load unit list",
          },
          fields: {
            location: {
              title: "Choose a Location",
              label: "Location",
              empty: "There are no units available",
              actions: {
                select: {
                  label: "Select",
                },
                change: {
                  label: "Choose a different location",
                },
              },
            },
            appointments: {
              awaitingCheckIn: "Awaiting check in",
              inProgress: "In progress",
              completed: "Appointment status",
              noAppointments: "There are no appointments scheduled",
              noInProgress: "There are no appointments in progress",
              noCompleted: "There are no completed visits",
              headings: {
                participant: "Participant",
                cohortId: "Cohort ID",
                time: "Time",
                duration: "Duration",
                type: "Type",
                accessibility: "Requirements",
                action: "Action",
                visitCompletedTime: "Visit completed time",
              },
              errors: {
                title: "Error fetching site schedule",
              },
              actions: {
                participantPage: {
                  label: "Participant page",
                },
                checkIn: {
                  label: "Check In",
                  loading: "Checking In ...",
                },
              },
            },
          },
        },
        participate: {
          form: {
            title: "Assign Participant ID",
            message:
              "Click the button below to assign the participant a participant ID",
            actions: {
              submit: "Assign ID",
            },
          },
          result: {
            title: "Assigned Participant ID",
            message:
              "Participant has been assigned the participant ID: {{studyId}}",
          },
          errors: {
            title: "Error loading participant status",
          },
        },
        preConsent: {
          title: "Consent",
          reConsent:
            "It has been more than 30 days since initial informed consent was given. It is, therefore, necessary to perform re-consent again.",
          message:
            "Did the participant consent to the study? (i.e. initial boxes 1-16)?",
          actions: {
            yes: "Yes",
            no: "No",
          },
          declined:
            "Participant has declined consent, please abort the appointment",
        },
        consent: {
          title: "Consent",
          fields: {
            document: {
              title: "Document Information",
            },
            researchOptIn: {
              text: "Did the participant consent to the optional question (i.e., initial box 17)?",
              options: {
                true: "Yes",
                false: "No",
              },
            },
            participant: {
              title: "Participant",
            },
            witness: {
              title: "Person Taking Consent",
            },
            name: {
              title: "Name",
              first: "First Name",
              last: "Last Name",
            },
            participantConsentDate: {
              title: "Participant's consent date",
            },
            witnessConsentDate: {
              title: "Person taking consent's witness date",
            },
            version: {
              label: "ICF and PIS version",
              placeholder: "Please select a version",
            },
          },
          summary: {
            version: "ICF and PIS version",
            witnessedAt: "Person taking consent's witness date",
            name: "Person taking consent",
            researchOptIn: {
              text: "Did the participant consent to the optional question (i.e., initial box 17)?",
              yes: "Yes",
              no: "No",
            },
            signedAt: "Participant's consent date",
          },
          actions: {
            submit: "Consent",
          },
          errors: {
            title: "Unable to submit consent record",
            timeInFuture: "Cannot be in the future",
          },
        },
        survey: {
          title: "Dear {{name}}",
          success: "You have successfully registered. Your reference: {{id}}",
          takingPart: {
            title: "Taking part",
            description:
              "There are some pre-existing conditions that mean some people cannot take part in the trial. These questions are to help decide whether you can take part.",
            warning: {
              title: "Important",
              body: "Please confirm your answers are correct before continuing. If you have answered 'Yes' to any of the above questions you will unfortunately not be able to take part in the trial and registration will end here.",
            },
            error: {
              title: "There is a problem",
            },
            medications: {
              title: "Reasons that may mean you cannot take part in the trial",
              paragraph1:
                "At your appointment, the trial nurse will check your medical history and any medicines you are taking to see if it is safe for you to take part.",
              paragraph2:
                "There are some medications that may interfere with how the trial test works.",
              paragraph3:
                "If you are taking Methotrexate or Cyclophosphamide for any reason you may not be able to take part in the trial. If you are taking any of the medication listed, please contact the trial team at participant_help@nhs-galleri.org or freephone 0800 030 9245 from 8am to 7pm, Monday to Friday (excluding bank holidays) before registering to take part.",
            },
          },
          age: {
            title: "Age eligibility",
            description:
              "This trial is only for people aged 50 to 77 (inclusive). This criteria is based on the age you were on the date stated on your invitation letter. This means if you have had your 78th birthday since that date, you can still answer 'yes' below.",
            warning: {
              title: "Important",
              body: "Please confirm your answer is correct before continuing. If you have answered 'No' to the above question you will unfortunately not be able to take part in the trial and registration will end here.",
            },
            error: {
              title: "There is a problem",
            },
          },
          questionUnansweredError: "Select an option",
          confirm:
            "Please confirm that your answers are correct before submitting",
          actions: {
            continue: "Continue",
          },
          errors: {
            title: "Error checking eligibility",
          },
          fields: {
            caption: "Are you eligible?",
            age: {
              label: "Over 65?",
              hint: "You must be over 65 to be eligible for the trial.",
            },
          },
          eligible: {
            title: "Medical and age details confirmed",
            description:
              "Thank you. You can now proceed to book your first appointment to attend a mobile clinic.",
            actions: {
              continue: {
                label: "Continue",
              },
            },
          },
          ineligible: {
            title: "Eligibility declined",
            description:
              "Unfortunately you cannot take part in this trial because:",
            moreInformation: {
              title: "More information",
              description:
                "If you have any questions relating to this decision, please contact the King's College London Cancer Prevention Trials Unit at <mailto>{{email}}</mailto> or call {{phoneNumber}}.",
            },
          },
        },
        dataSharingConsent: {
          title: "Data consent",
          errorTitle: "Something went wrong",
          rejectedTitle: "Data consent not given",
          error:
            'There was an error downloading the data sharing consent "{{error}}", please try later.',
          rejected:
            "By not agreeing to these uses of your contact information, you cannot take part in this trial because we require your consent to use your personal contact information to arrange your appointment.",
          actions: {
            back: "Go back",
            accept: "Yes, I consent",
            reject: "No, I do not consent",
          },
        },
        vouchers: {
          title: "Enter a voucher code",
          actions: {
            submit: "Submit",
            submitting: "Submitting",
          },
          accepted: "Voucher code has been recorded: {{barcode}}",
          declined: "Declining voucher has been recorded.",
          errors: {
            title: "There was a problem with your submission",
            alreadyClaimed: "The voucher has already been claimed.",
          },
          fields: {
            barcode: {
              label: "Barcode",
              hint: "Find the numbers listed under the voucher barcode",
              errors: {
                empty:
                  "Barcode must be provided if the participant did not decline the voucher",
                invalid:
                  "Barcode is not required if participant declined the voucher",
              },
            },
            status: {
              label: "Voucher status",
              hint: "",
              options: {
                declined: "Declined",
                accepted: "Accepted",
              },
              errors: {
                invalid: "You must provide a voucher status",
              },
            },
            type: {
              label: "Voucher type",
              hint: "",
              options: {
                asda: "ASDA",
                tesco: "Tesco",
                sainsbury: "Sainsbury's",
                morrisons: "Morrisons",
                argos: "Argos",
              },
              errors: {
                invalid: "You must provide a voucher type",
              },
            },
          },
        },
        registration: {
          fields: {
            nhsNumber: {
              label: "NHS number",
              hint: "An NHS number is a 10 digit number, like 485 777 3456.",
              errors: {
                invalid: "Enter a valid NHS number",
              },
            },
            invitationCode: {
              label: "Invitation Code",
              hint: "In order to register to take part in this trial, you should have been sent an invitation code. You can find this on your invitation letter.",
              errors: {
                invalid: "Enter a valid invitation code",
              },
            },
            firstName: {
              label: "First name",
              errors: {
                empty: "Enter your first name",
              },
            },
            lastName: {
              label: "Last name",
              errors: {
                empty: "Enter your last name",
              },
            },
            dateOfBirth: {
              label: "Date of birth",
              hint: "For example, 15 3 1965",
              errors: {
                invalid: "Enter a valid date of birth",
                invalidDay: "Enter a day",
                invalidMonth: "Enter a number between 1 and 12 for the month",
                invalidYearFormat: "Enter a valid 4 digit year",
                invalidYearRange:
                  "You must be between 50 and 77 years of age when you received your invitation letter in order to take part in the Galleri trial.",
              },
            },
            biologicalSex: {
              label: "Sex assigned at birth",
              options: {
                male: "Male",
                female: "Female",
              },
              errors: {
                invalid: "Select your biological sex",
              },
            },
            email: {
              label: "Email address (optional)",
              hint: "If you provide this we can send you reminders when it is time for your appointment.",
              errors: {
                invalid: "If you provide an email, it must be valid",
              },
            },
            phoneNumber: {
              label: "Main phone number",
              hint: "We need this if someone from the trial team needs to talk to you.",
              errors: {
                invalid:
                  "Enter a valid telephone number like 01632 960 001 or 07700 900 982",
              },
            },
            mobilePhoneNumber: {
              label: "Mobile phone number (optional)",
              hint: "If the number you have given above is not for a mobile phone, you can also provide one to receive SMS reminders when it is time for your appointment.",
              errors: {
                invalid: "Enter a valid UK mobile number like 07700 900 982",
              },
            },
            gpPracticeName: {
              label: "Practice Name",
              hint: "",
              errors: {
                empty: "You must provide your GP's practice name",
              },
            },
            gpOrgId: {
              errors: {
                noneSelected:
                  "No GP selected. Please search for a GP using the box above and select one from the list. If your GP does not appear, please check the postcode and try again.",
              },
            },
            gpName: {
              label: "Name of GP (optional)",
              hint: "This is your named doctor, not the name of the practice or surgery",
            },
          },
          sections: {
            progressMessage: "Step {{stepNumber}} of {{maxSteps}}",
            continue: "Continue",
            aboutYou: {
              title: "About you",
            },
            invite: {
              title: "Register your details",
            },
            contactInformation: {
              title: "Contacting you",
            },
            postalAddress: {
              title: "What is your postal address?",
              subheading:
                "We need this to send your appointment letter in the post.",
            },
            findGp: {
              title: "Your GP",
              nextLabel: "Find address",
            },
            selectGp: {
              title: "Your GP address",
              summary: {
                title: "Postcode",
                action: "Change",
              },
              nextLabel: "Continue",
            },
            confirm: {
              title: "Confirm your details",
              subheading:
                "Please confirm that your details are correct before continuing.",
              summary: {
                nhsNumber: "NHS number",
                gp: "GP",
                name: "Name",
                dob: "Date of birth",
                sex: "Sex assigned at birth",
                contact: "Contact details",
                address: "Address",
              },
              actions: {
                change: "Change",
              },
            },
          },
          errors: {
            submissionErrorTitle: "Your submission cannot be completed",
            invitationErrorTitle: "Your submission cannot be completed",
          },
        },
        participantDetailsManager: {
          fields: {
            nhsNumber: {
              label: "NHS number",
              hint: "An NHS number is a 10 digit number, like 485 777 3456.",
              errors: {
                invalid: "Enter a valid NHS number",
              },
            },
            lastName: {
              label: "Last name",
              hint: "This should be the name you used when you registered to the trial. If your legal name has changed since your last appointment, you can update this at the next step.",
              errors: {
                empty: "Enter your last name",
              },
            },
            dateOfBirth: {
              label: "Date of birth",
              hint: "For example, 15 3 1965",
              errors: {
                invalid: "Enter a valid date of birth",
                invalidDay: "Enter a day",
                invalidMonth: "Enter a number between 1 and 12 for the month",
                invalidYearFormat: "Enter a valid 4 digit year",
              },
            },
          },
          sections: {
            find: {
              title: "Find your details",
              nextLabel: "Continue",
            },
            confirm: {
              title: "Confirm your details",
              subheading:
                "Thank you. You can now update any information that may have changed since you registered for the trial.",
              summary: {
                nhsNumber: "NHS number",
                gp: "GP",
                name: "Name",
                dob: "Date of birth",
                sex: "Sex assigned at birth",
                contact: "Contact details",
                address: "Address",
              },
              callout:
                "Please confirm that your details above are correct before continuing.",
              actions: {
                change: "Change",
              },
              nextLabel: "Confirm details",
            },
            changeDetails: {
              findGP: {
                title: "Your GP",
                nextLabel: "Find address",
              },
              selectGP: {
                title: "Your GP address",
                summary: {
                  title: "Postcode",
                  action: "Change",
                },
                nextLabel: "Continue",
              },
              name: {
                title: "Update your name",
                nextLabel: "Continue",
                errors: {
                  emptyFirstName: "Enter your first name",
                  emptyLastName: "Enter your last name",
                },
              },
              sex: {
                title: "Changing sex assigned at birth",
                nextLabel: "Continue",
                errors: {
                  invalidSex: "Select your biological sex",
                },
              },
              contact: {
                title: "Update your contact details",
                errors: {
                  invalidPhone:
                    "Enter a valid telephone number like 01632 960 001 or 07700 900 982",
                  invalidMobilePhone:
                    "Enter a valid UK mobile number like 07700 900 982",
                  invalidEmail: "If you provide an email, it must be valid",
                },
                nextLabel: "Continue",
              },
              postalAddress: {
                title: "Update your address",
                nextLabel: "Continue",
              },
            },
            noParticipant: {
              title: "Details not found",
              paragraph1: "Sorry, we cannot find your details.",
              paragraph2:
                "If you have already attended an appointment to take part in the NHS-Galleri trial, please contact the trial team at participant_help@nhs-galleri.org or on freephone 0800 030 9245 from 8am to 7pm, Monday to Friday (excluding bank holidays).",
            },
            yxEligible: {
              personalisedTitle: "Welcome back, {{name}}!",
              defaultTitle: "Welcome back to the NHS Galleri trial!",
              paragraph1:
                "Thank you for being one of more than 140,000 people volunteering to take part in the NHS-Galleri trial. By taking part, you are helping the NHS to understand if using the Galleri blood test alongside existing cancer screening can help detect cancer early.",
              paragraph1_Y2:
                "Thank you for being one of more than 140,000 people volunteering to take part in the NHS-Galleri trial. By being involved, you are helping the NHS to understand if using the Galleri blood test alongside existing cancer screening can help detect cancer early. Finding cancer early often means it is easier to treat.",
              paragraph2:
                "The appointment will be around 15 minutes, and it’s really important that you attend to give a blood sample and complete some surveys about your health. This will help the NHS understand how to offer the test to people in the future.",
              paragraph2_Y2:
                "It is now time to book your third (24 month) appointment. This will be your last appointment for the NHS-Galleri trial. The appointment will take around 15 minutes, and you will be asked to give a blood sample and complete a short survey about your health.",
              warning: {
                title: "Important",
                body: "If you have been treated for cancer in the last year, you may not need to attend for a blood test. Please contact the trial team at participant_help@nhs-galleri.org or freephone 0800 030 9245 from 8am to 7pm, Monday to Friday (excluding bank holidays) and ask to speak to the study nurse.",
                body_Y2:
                  "If you have been treated for cancer since your last appointment, you may not need to attend for a blood test. Please contact the trial team at participant_help@nhs-galleri.org or call freephone 0800 030 9245 (8am to 7pm, Monday to Friday, excluding bank holidays).",
              },
              actions: {
                continue: "Book now",
              },
              errors: {
                checkCanBook: {
                  title: "Error when checking if appointment can be booked",
                  bookableButNotY1:
                    "12 month blood draw appointment is not supposed to be booked at this stage",
                  bookableButNotY2:
                    "24 month blood draw appointment is not supposed to be booked at this stage",
                },
              },
              checkCanBook: {
                visitCompleted: {
                  title: "Appointment completed",
                  description: `
                    <p>You have completed your 12 month appointment.</p>
                    <p>If you have any questions please contact $t(contact.email) or call $t(contact.phoneNumber) from 8am to 7pm, Monday to Friday (excluding bank holidays).</p>
                  `,
                },
                personWithdrawn: {
                  title: "Withdrawn from trial",
                  description:
                    "<p>It looks like you are withdrawn from the trial. Please contact the call centre for further information on $t(contact.phoneNumber) from 8am to 7pm, Monday to Friday (excluding bank holidays), or email $t(contact.email).</p>",
                },
                unspecified: {
                  title: "Unable to book appointment",
                  description: `
                    <p>The NHS-Galleri trial is investigating the use of the Galleri blood test to detect cancer in people with no symptoms of cancer.</p>
                    <p>Our records indicate that you do not need to attend any more appointments. If you would like further information, please contact the trial team at participant_help@nhs-galleri.org or call $t(contact.phoneNumber) from 8am to 7pm, Monday to Friday (excluding bank holidays).</p>
                    <p>Thank you for playing your part in the trial and helping research into early cancer detection.</p>
                  `,
                },
                excluded: {
                  title: "Unable to book appointment",
                  description:
                    "<p>You are currently unable to book an appointment. Please call $t(contact.phoneNumber) and ask to speak to the research nurses, who will be able to discuss this with you further.</p>",
                },
                personOutsideEngland: {
                  title: "Outside of England",
                  description:
                    "<p>As you are no-longer living in England we are no longer able to book you into a 12 month appointment. If you believe this to be incorrect, please email $t(contact.email) or contact the call centre to discuss this further on $t(contact.phoneNumber) from 8am to 7pm, Monday to Friday (excluding bank holidays).</p>",
                },
                notAuthorised: {
                  title: "Blood redraw",
                  description:
                    "<p>Please contact the call centre on $t(contact.phoneNumber) from 8am to 7pm, Monday to Friday (excluding bank holidays), or email $t(contact.email) for blood redraw appointments</p>",
                },
                existingAppointment: {
                  title: "Your 12-month appointment",
                  description: `
                    <p>You have already booked your next blood test. Please find your appointment details below.</p>
                    <p>If you are no longer able to attend, please contact the trial team at $t(contact.email) or call freephone $t(contact.phoneNumber) from 8am to 7pm, Monday to Friday (excluding bank holidays).</p>
                  `,
                },
                y1BookingDisabled: {
                  title: "Details confirmed",
                  description: `
                    <p>Thank you for confirming your details.</p>
                    <p>It is not yet time to book your 24-month appointment. Look out for your letter, which will tell you when it is time
                      to book.</p>
                    <p>Thank you for taking part in the NHS-Galleri trial.</p>
                    <p>Information about the trial can be found at <nhsGalleriLink>www.nhs-galleri.org</nhsGalleriLink>.</p>
                  `,
                },
              },
            },
            notYxEligible: {
              title: "Details confirmed",
              paragraph1: "Thank you for confirming your details.",
              paragraph2:
                "It is not yet time to book your next appointment. You will receive a letter in the post when it is time to book your appointment.",
              paragraph3: "Thank you for taking part in the NHS-Galleri trial.",
              paragraph1_Y2:
                "Thank you for updating your details. This information is important for us to be able to communicate with you during your participation in the NHS Galleri trial. Please wait for your invitation letter before coming back to book your appointment.",
              paragraph2_Y2: "",
              paragraph3_Y2: "",
            },
            addressOutsideOfEngland: {
              title: "Out of trial area",
              paragraph1:
                "The postcode you have provided is for an area where the trial is not taking place.",
              paragraph2:
                "Please call freephone 0800 030 9245 from 8am to 7pm, Monday to Friday (excluding bank holidays). You may need to travel further to attend.",
            },
          },
          errors: {
            submissionErrorTitle: "Your submission cannot be completed",
          },
        },
        participant: {
          appointments: {
            title: "Appointments",
            headings: {
              list: "Appointments",
              empty: "No appointments scheduled at this time",
              time: "Time and date",
              location: "Location",
              type: "Type",
              status: "Status",
              requirements: "Accessibility requirements",
            },
            loading: "Loading appointments",
            empty: "No appointments",
            actions: {
              cancelOrRebook: {
                label: "Cancel/Rebook",
                title: "Cancel or rebook this appointment?",
                information:
                  "Please confirm that the participant wants to cancel their appointment and not rebook. Let the participant know that you may be able to help them to find an appointment time that suits them.",
                rebook: "Check availability & rebook",
                cancel: "Cancel appointment",
                error: "Rebook error",
                notBookableError:
                  "Cannot rebook appointment. Please check the participant is within their booking window",
              },
              cancel: {
                title: "Are you sure you wish to cancel this appointment?",
                reasonLabel: "Why is this appointment being cancelled?",
                reasonPlaceholder: "Select a reason",
                label: "Cancel",
                confirm: "Confirm",
                return: "Return",
                reason: {
                  NO_REASON_PROVIDED: "No reason provided",
                  LOCATION_UNSUITABLE: "Cannot find suitable location",
                  TIME_UNSUITABLE: "Cannot find suitable date/time",
                  MOBILITY_ISSUES: "Participant has mobility issues",
                  NOT_IN_COUNTRY: "Participant not in the country/travel",
                  WORK_OR_FAMILY_COMMITMENTS:
                    "Participant has work/family commitments",
                  OUTSIDE_OF_ENGLAND: "Participant no longer lives in England",
                  OTHER: "Other",
                },
              },
              book: {
                unable: {
                  title: "Unable to book appointment",
                  reason: {
                    visitCompleted:
                      "Participant has already completed their appointment",
                    personWithdrawn:
                      "Participant has withdrawn from the trial and cannot book another appointment",
                    notAuthorised:
                      "You are not authorised to book re-draw appointments",
                    existingAppointment:
                      "Participant has already booked an appointment",
                    personOutsideEngland:
                      "Participant is marked as outside of England",
                    d1BookingDisabled:
                      "D1 appointments are no longer available",
                    y1BookingDisabled:
                      "Y1 appointments are no longer available",
                    unspecified:
                      "It is not possible to book an appointment for this participant. Please refer to the KCL team",
                    excluded:
                      "This participant is currently unable to book an appointment. Please advise that you will transfer them through to the Research Nurses for further discussion",
                  },
                },
                label: "Book an appointment",
              },
              checkIn: "Check In",
              continue: "Continue",
            },
            errors: {
              noAppointments: "No appointments scheduled at this time",
              networkError: "There was a problem fetching appointments",
              noCohortId:
                "Unable to find appointments for participant without Cohort ID",
              label: "participant-appointments-error-title",
              cancelAppointmentError:
                "There was an issue cancelling the appointment.",
            },
          },
          cancelledAppointments: {
            errors: {
              noAppointments: "No cancelled appointments found.",
              networkError:
                "There was a problem fetching cancelled appointments.",
              label: "Submission error",
            },
            title: "Cancelled Appointments",
          },
          notes: {
            title: "Notes",
            headings: {
              note: "Note",
              author: "Author",
              createdAt: "Created",
            },
            loading: "Loading notes...",
            empty: "No notes",
            actions: {
              add: {
                label: "Add a new note",
                button: "Add note",
              },
            },
            errors: {
              load: "Error loading notes",
              save: "Error adding new note",
              lengthExceeded:
                "Your note exceeds the {{maxNoteLength}} character limit",
            },
          },
          communications: {
            title: "Communications sent by CRMS",
            headings: {
              time: "Time and date",
              channel: "Channel",
              type: "Type",
            },
            loading: "Loading communications...",
            empty: "No communications",
            channel: {
              LETTER: "Letter",
              SMS: "SMS",
              EMAIL: "Email",
            },
          },
        },
        search: {
          noResult: "No results.",
          results: {
            label: "Search results",
          },
          headings: {
            address: "Address",
            dateOfBirth: "Date of birth",
            cohortId: "Cohort ID",
            participantId: "Participant ID",
          },
          errors: {
            label: "Submission error",
            searchErrorTitle: "There was a problem with your query.",
          },
        },
        unit: {
          select: {
            title: "List of Units",
            label: "Unit {{code}}",
            error: "Unable to load unit list",
            actions: {
              location: "Location",
              capacity: "Capacity",
              addUnit: "Add Unit",
            },
          },
          capacity: {
            errors: {
              submissionErrorTitle: "Error loading schedule",
              unableToLoad: "unable to load schedule",
              unableToLoadAppointments: "unable to load appointments",
              update: {
                invalidNumber: {
                  label: "Invalid number",
                },
                failedToUpdate: {
                  label: "Failed to update",
                },
              },
              cache: {
                label: "Caching issue",
                error:
                  "The capacity was added to the system, but due to a temporary technical issue, it might not be visible to the users querying it at the moment. The next update to any of the units' locations or availabilities will attempt to reset the cache again, which should solve this issue. Contact the system administrator for additional assistance.",
              },
            },
            update: {
              capacity: {
                label: "update capacity",
              },
              status: {
                label: "update status",
              },
            },
            status: {
              PUBLISHED: "Active",
              DRAFT: "Draft",
            },
            actions: {
              add: "Add Unit Capacity",
              updateCapacity: "Update",
              saveCapacity: "Save",
            },
            table: {
              activeFrom: {
                label: "Start Date",
              },
              activeTo: {
                label: "End Date",
              },
              capacity: {
                label: "Capacity",
              },
            },
            add: {
              errors: {
                overlaps: "Overlaps with existing set capacity",
                inPast: "Capacity cannot be set in the past.",
                invalidNumber: "Invalid number",
              },
              title: "Add Unit Capacity",
              actions: {
                addRow: "Add",
                deleteRow: "Delete",
                cancel: "Cancel",
                save: "Save",
              },
              inputs: {
                activeFrom: {
                  label: "Start Date",
                },
                activeTo: {
                  label: "End Date",
                },
                startTime: {
                  label: "Start Time",
                },
                endTime: {
                  label: "End Time",
                },
                days: {
                  label: "Days of the week",
                  monday: {
                    label: "Monday",
                  },
                  tuesday: {
                    label: "Tuesday",
                  },
                  wednesday: {
                    label: "Wednesday",
                  },
                  thursday: {
                    label: "Thursday",
                  },
                  friday: {
                    label: "Friday",
                  },
                  saturday: {
                    label: "Saturday",
                  },
                  sunday: {
                    label: "Sunday",
                  },
                },
                capacity: {
                  label: "Capacity per appointment",
                },
              },
            },
          },
          location: {
            table: {
              header: {
                from: "From",
                to: "To",
                location: "Location",
              },
            },
            actions: {
              add: "Add Location",
            },
            add: {
              inputs: {
                activeTo: "Active to",
                activeFrom: "Active from",
              },
              errors: {
                overlaps: "Dates overlap existing location",
                activeToBeforeActiveFrom:
                  "Active to must be later than active from",
                cache: {
                  label: "Caching issue",
                  error:
                    "The location was created in the system, but due to a temporary technical issue, it might not be visible to the users querying it at the moment. The next update to any of the units' locations or availabilities will attempt to reset the cache again, which should solve this issue. Contact the system administrator for additional assistance.",
                },
              },
              title: "Add unit location",
              directions: {
                subheading: "Directions",
                inputs: {
                  directions: {
                    label:
                      "Direction instruction to the unit location (optional)",
                  },
                },
              },
            },
          },
          add: {
            errors: {
              unitCodeExists: "Unit code already exists",
              invalidUnitCode: "Unit code is not valid",
            },
            title: "Add a unit",
            fields: {
              unitCode: {
                label: "Unit Code",
              },
              unitAccessible: {
                label: "This unit is accessible",
              },
            },
            actions: {
              save: "Save",
              cancel: "Cancel",
            },
          },
        },
        eligibility: {
          title: "Eligibility assessment",
          eligible: {
            title: "Eligibility confirmed",
            description: "The participant is eligible for the study.",
          },
          ineligible: {
            title: "Eligibility declined",
            description:
              "Unfortunately the participant cannot take part in this trial because:",
            reasons: {
              participatedInOtherGrailStudy:
                "they have previously participated in a GRAIL study",
              cancerHistory:
                "a doctor has told them they have cancer (including solid cancers, blood cancers or bone marrow cancers)",
              cytotoxicDemethylatingAgents:
                "they are currently taking methotrexate or a cancer treatment for another illness",
              ongoingCancerInvestigations:
                "they are currently having tests or waiting for tests for suspected cancer",
              palliativeCare:
                "they are currently receiving palliative care or end of life treatment?",
            },
          },
          amend: {
            title: "Amend eligibility answers",
            description:
              "If the eligibility answers have been entered incorrectly, please use the button below to amend.",
            button: "Amend eligibility",
            checkbox:
              "Click to confirm the site lead is present and approves the change in eligibility",
            confirm: "Continue",
          },
          errors: {
            title: "Error retrieving eligibility information",
          },
        },
        eligibilityCheck: {
          title: "Eligibility check",
          instruction:
            "Please check if the participant's eligibility criteria have changed since their last study visit.",
          actions: {
            amend: "Amend eligibility questions",
            continue: "Continue",
          },
          errors: {
            loadError: "Error loading eligibility form",
            actionError: "Error submitting eligibility",
          },
        },
        ecrf: {
          summary: {
            title: "Form summary",
            instruction: "Please review the answers below before submitting.",
            checkbox: "Confirm the summary is correct",
          },
        },
        questionnaire: {
          instruction: "Start Questionnaire",
          completed: "Questionnaire has been completed.",
          errors: {
            title: "Error retrieving questionnaire",
          },
        },
        voucher: {
          completed: "Voucher submission has been recorded",
        },
        bloodDraw: {
          title: "Blood Draw",
          completed: "Blood draw form has been completed",
          abortSuggestion:
            "Blood collection was not successful. Please abort this visit.",
        },
        trfClinicalData: {
          title: "TRF Clinical data",
          completed: "TRF clinical data form has been completed",
        },
        video: {
          title: "Information Sheet Video",
          form: {
            instruction:
              "You should be given a Participant Information Sheet video to watch.",
            hint: "Please watch the video",
            acknowledgement: "I have seen the video.",
            submit: "Submit",
            subtitleLabels: {
              en: "English",
              bn: "Bengali",
              gu: "Gujarati",
              pa: "Punjabi",
              ur: "Urdu",
            },
          },
          download: "Downloading video: {{mb}}MB",
          successScreen: {
            message: "You have accepted the contents of the video.",
            returnLink: "Continue to the next step",
          },
          errors: {
            title: "Server error",
          },
        },
        results: {
          title: "Test Reports",
          filters: "Filter worklist",
          searchHeader: "Search by name or participant ID",
          searchFilters: {
            participantId: "Participant ID",
            name: "Name",
            cohortId: "Cohort ID",
          },
          searchButton: "Search",
          unread: "Unread",
          inProgress: "In progress",
          closed: "Closed",
          table: {
            status: "Status",
            fullName: "Participant",
            bloodDraw: "Blood draw",
            progress: "Progress",
            daysSince: "Last updated by",
            reportIssued: "Report issued",
            noResults: "No Test Reports found",
            tags: {
              unread: "UNREAD",
              inProgress: "IN PROGRESS",
              closed: "CLOSED",
            },
          },
        },
        resultsCase: {
          beforeYouBegin: {
            title: "Before you begin",
            answer: {
              label: `<p>Does the Participant ID <strong>{{studyID}}</strong> match the Patient ID in the PDF test report file listed below?</p>`,
              yes: "Yes",
              no: "No",
            },
          },
          note: {
            label: "Add Note",
          },
          noStudyId: "The person has no associated participant ID.",
          summary: {
            details: {
              dob: "Date of birth",
              nhs: "NHS number",
              cohortId: "Cohort ID",
              studyId: "Participant ID",
              appointmentAddress: "Unit attended",
              accessibility: {
                name: "Accessibility",
                interpreter: "Language translation - ",
              },
              y0Result: "Y0 Result",
              y1Result: "Y1 Result",
              y2Result: "Y2 Result",
              result_PRESENT: "Positive",
              result_ABSENT: "Negative",
              result_UNKNOWN: "Unknown",
              result_CANCELLED: "Cancelled",
              result: "No data available",
            },
            status: {
              OPEN: "Open",
              CLOSED: "Closed",
            },
            statusLabel: "Change status",
            saveButton: "Save",
            confirmParticipantNotified: "Participant notified",
          },
          participantCard: {
            title: "1. Call participant",
            attemptsSubheading: "Contact attempts",
            attempt: "Call attempt {{attemptNumber}}",
            participantNotified: "Participant notified",
            callUnsuccessful: "Call unsuccessful",
            letterPrompt:
              "After three unsuccessful attempts at contact, send the letter instructing participant to contact KCL nurse immediately to:",
            letterSent: "Letter sent to participant",
            contactDetails: "Contact Details",
            notesHeading: "Notes",
            notesLoading: "Loading notes...",
            notesError: "Error loading notes",
            contactNote: "Add note",
          },
          reportPDF: {
            download: "Click to download",
          },
          referralCard: {
            title: "4. Submit two-week wait referral",
            referralStatus: "Referral status",
            submitted: "Two-week wait referral submitted",
            submittedToday: "Today ({{date}})",
            other: "Other",
            dateError: "Invalid date",
            documentsToInclude: {
              header: "Documents to include",
              report: "Galleri Test Report",
              proforma: "e-Referral Pro Forma",
            },
          },
          referralLogCard: {
            title: "6. Log two-week wait referral status",
            label: "Two-week wait referral status",
            accepted: "Accepted",
            rejected: "Rejected",
          },
          document: {
            toMail: "Documents to mail:",
            mailTo: "Mail to:",
            openDocmail: "Send documents via Docmail",
          },
          participantDocCard: {
            title: "3. Send documents to participants",
            documents: {
              successful: {
                label: "If Contact Successful",
                participantLetter:
                  "Letter informing participant of test result",
                participantQuestionnaire: "Participant questionnaire",
              },
              unsuccessful: {
                label: "If Contact Unsuccessful",
                participantLetter:
                  "Letter instructing participant to contact KCL nurse immediately",
              },
            },
            checkbox: "Documents mailed to participant",
          },
          GpDocCard: {
            title: "5. Send documents to GP",
            documents: {
              gpLetter: "Letter to GP",
              galleriTestReport: "Galleri Test Report",
            },
            checkbox: "Documents mailed to GP",
          },
          ECRFCard: {
            title: "2. Submit eCRF",
            openLink: "Open EDC",
            instruction: {
              title: "Once you click the link, please:",
              makeSure:
                'Make sure the user has access to and is logged in as "Research Nurse" in GRAIL EDC',
              clickOnCreateForm:
                'Click on "Create A New Form" and select "D1 Results Communication" option',
              selectTestResult: 'Fill out "D1 Results Communication" eCRF',
            },
            checkbox: "eCRF form submitted",
          },
        },
        visit: {
          completed:
            "Visit completed for participant with cohort ID: {{cohortId}}",
          abort: {
            title: "End appointment",
            select: {
              label: "Why was the appointment ended?",
              placeholder: "Select a reason",
              hint: "",
              errors: {
                invalid: "You must select a reason for aborting",
              },
              status: {
                inProgress: "In progress",
                completed: "Completed",
                aborted: "Aborted",
                selfDecision: "Invitee/participant's choice",
                ineligible: "Invitee ineligible to continue",
                notConsented: "Invitee not consented",
                operational: "Operational issues",
                technical: "Technical issues",
                other: "Other",
              },
            },
            text: "Appointment has been aborted.",
            provideReason: "Comments (optional)",
            provideReasonHint: " {{charactersLeft}} characters remaining",
            provideReasonError: "Length limit exceeded",
            abortButton: "End appointment",
          },
          person: {
            name: "Name",
            dateOfBirth: "Date of birth",
            cohortId: "Cohort ID",
            participantId: "Participant ID",
          },
          links: {
            next: "Next: {{step}}",
            returnToUnitSchedule: "Return to unit schedule",
            abortAppointment: "End appointment",
            goToProfile: "Go to person profile: {{cohortId}}",
          },
          errors: {
            serverError: "Server error",
            loadVisitError: "Error loading visit information",
            submissionError: "Submission error",
            loadPersonError: "Error loading person information",
          },
          confirm: {
            title: "Confirm participant details",
            hint: "Confirm details",
            acknowledgement:
              "The participant has confirmed these details are correct",
          },
        },
        user: {
          actions: {
            sync: "Sync",
            loading: "Loading",
            addNewUser: "Add new user",
          },
          tab: {
            active: "Active",
            archived: "Archived",
            header: "Status",
          },
          table: {
            header: {
              username: "Username",
              user: "User",
              archived: "Archived",
            },
          },
          profile: {
            updateMFA: {
              title: "Update MFA",
              errors: {
                label: "Failed to update MFA",
                description: "Failed to update MFA for user",
              },
            },
            actions: {
              resetPassword: "Reset Password",
              setTOTP: {
                label: "TOTP",
                loading: "TOTP...",
              },
              setSMS: {
                label: "SMS",
                loading: "SMS...",
              },
              archive: "Archive user",
            },
            roleTable: {
              header: {
                username: "Username",
              },
            },
          },
          updateProfile: {
            title: "Update your profile",
            actions: {
              submit: "Submit",
            },
            firstName: {
              label: "First name",
              errors: {
                empty: "Please provide your first name",
              },
            },
            lastName: {
              label: "Last name",
              errors: {
                empty: "Please provide your last name",
              },
            },
            submissionError: {
              label: "Update profile error",
            },
          },
          createUser: {
            title: "Add New User",
            phoneNumber: {
              label: "Phone number",
              errors: {
                invalid: "Please provide a valid phone number",
                numberStartsWith: "Phone number must not begin with a 0",
              },
            },
            email: {
              label: "Email",
              errors: {
                invalid: "Please provide a valid email address",
              },
            },
            firstName: {
              label: "First Name",
            },
            lastName: {
              label: "Last Name",
            },
            actions: {
              submit: "Create",
              selectRoles: "Select roles to assign",
              back: "Go Back",
            },
            submissionError: {
              label: "Submission error",
              userAlreadyExists: "User already exists",
            },
          },
          resetPassword: {
            title: "Confirmation",
            content: "Are you sure you want to reset this user's password?",
            actions: {
              cancel: "No",
              ok: "Yes",
            },
            successMessage:
              "The account password has been reset. An email containing the instruction to reset the password has been sent to the user.",
            errors: {
              title: "Reset user's password error",
              actionLimitExceeded:
                "The user's password has been reset many times. Please wait and try again later.",
            },
          },
          setPassword: {
            title: "Set Password",
            username: {
              label: "Username",
              errors: {
                empty: "Please provide the username",
              },
            },
            confirmationCode: {
              label: "Code",
              errors: {
                empty: "Please provide the code",
              },
            },
            password: {
              label: "Password",
              errors: {
                empty: "Please provide the password",
              },
            },
            passwordCheck: {
              label: "Confirm password",
              errors: {
                empty: "Please re-enter the password",
                different:
                  "Password is not the same as the re-entered password",
              },
            },
            actions: {
              submit: "Set Password",
            },
            submissionError: {
              label: "Set password error",
            },
          },
          updatePhoneNumber: {
            title: "Update Phone Number",
            existingPhoneNumber: {
              label: "Existing phone number",
            },
            phoneNumber: {
              label: "New phone number",
              errors: {
                invalid: "Please provide a valid phone number",
                different:
                  "Phone number is not the same as the re-entered phone number",
              },
            },
            confirm: {
              label: "I confirm I want to change this user's phone number",
              error: "Please confirm you want to change the users phone number",
            },
            checkPhoneNumber: {
              label: "Re-enter new phone number",
            },
            actions: {
              submit: "Update",
            },
            submissionError: {
              label: "Submission error",
            },
          },
          archived: {
            profile: {
              title: "Archived user",
              name: "Name",
              contactDetails: "Contact details",
              userRoles: "User roles",
              archivedDate: "Archived date",
              archivedBy: "Archived by",
              reason: "Reason",
              notes: "Notes",
              reinstate: "Reinstate user",
            },
          },
          archive: {
            title: "Archive user",
            reasonLabel: "Why is this user being archived?",
            reasonPlaceholder: "Select a reason",
            noteLabel: "Notes (optional)",
            action: "Archive user",
            cancel: "Cancel",
            error: "Unable to archive user",
          },
        },
        reports: {
          error: "Error",
          dateError: "Invalid date",
          dateMissingError: "You must enter a date",
          dateOrderError: "To date cannot be before From",
          title: "Generate Reports",
          fromDate: "From",
          toDate: "To",
          reportTypeSelector: "Report type",
          download: "Download Report",
          trialStart: "Trial start: 1 August 2021",
          otherFrom: "Other date",
          select: {
            appointments: "Appointments",
            bloodDrawsPerDay: "Blood draws per day",
            y1LabForecast: "Y1 Lab Forecast",
            bloodDrawsPerUnitOrNurse: "Blood draws per unit / nurse",
            falsePositives: "False Positives",
            cumulativeRetention: "Cumulative retention",
            y2CumulativeRetention: "Y2 Cumulative retention",
            mobileClinicsDailyTrends: "Daily trends for all mobile clinics",
            retentionByLocationAndEthnicity:
              "Retention by location and ethnicity",
            retentionByLocationAndImd: "Retention by location and IMD",
            retentionByLocationAndAgeAndGender:
              "Retention by location and age and gender",
            appointmentNotBooked: "Appointment not booked",
            y2MobileClinicsDailyTrends:
              "Y2 Daily trends for all mobile clinics",
            participantReportedStatus: "Participant Reported Status",
          },
        },
        notParticipating: {
          title: "Mark as no longer participating in blood draws",
          warning: {
            label: "Important",
            text: "Marking a study end point impacts a participant's ability to participate in the NHS-Galleri trial.",
          },
          instruction: `<p>To confirm no longer participating in blood draws, please enter ‘<strong>confirm</strong>’ in the box below (case sensitive) to proceed</p>`,
          button: "Confirm change",
          error: "Unable to mark as no longer participating in blood draws",
          confirmation:
            "Participant successfully marked as no longer participating in blood draws",
        },
        removeNotParticipating: {
          title: "Remove no longer participating in blood draws",
          instruction: `<p>To confirm the removal of this study endpoint, please enter ‘<strong>confirm</strong>’ in the box below (case sensitive) to proceed</p>`,
          button: "Confirm change",
          error: "Unable to remove no longer participating in blood draws",
          confirmation:
            "Successfully removed no longer participating in blood draws",
        },
        audit: {
          title: "User Events",
          eventHeading: "Show Events",
          monthHeading: "By month",
          action: "Search",
          events: {
            users: {
              all: "Users",
              hint: "New User, User Changes, User Role, Archiving",
              new: "New User Created",
              changed: "User Details Changed",
              userRole: "User Role Changed",
              archiving: "User Archived",
            },
            signIns: {
              all: "Sign-in",
              hint: "Sign-ins, Password Resets",
              signIns: "Sign-ins",
              passwordChange: "Password Reset Requested",
              forgotPassword: "User Changed Password",
            },
            roles: {
              all: "Roles",
              hint: "New Roles Created, Roles Changed",
              new: "New Role Created",
              changed: "Role Changed",
            },
          },
          resultsHeading: "Showing {{num}} results",
          tooManyResults: {
            heading: "Too many results to show",
            instruction:
              "Please download the results of your search in .csv format using the button above and view these offline.",
          },
          downloadResults: "Download Results (.csv format)",
          downloadAll: "Download all audit log (.csv format)",
        },
        auditEvents: {
          eventHeader: "Event",
          timestampHeader: "Timestamp",
          createEvent: `<strong>{{subject}}</strong> {{entity}} was created`,
          updateEvent: `<strong>{{subject}}</strong> {{entity}} was updated`,
          deleteEvent: `<strong>{{subject}}</strong> {{entity}} was deleted`,
          by: `by <strong>{{initiator}}</strong>`,
          createField: "New {{field}} added: {{value}}",
          updateField: `{{field}} changed: {{old}} \u2192 {{new}}`,
          updateAddingField: "{{field}} added: {{new}}",
          updateRemovingField: "{{field}} removed: {{old}}",
          deleteField: "{{field}} removed: {{value}}",
          actions: {
            SignIn: {
              pass: `<strong>{{subject}}</strong> successfully signed in`,
              fail: `<strong>{{subject}}</strong> unsuccessfully tried to sign in`,
              inprogress: `<strong>{{subject}}</strong> sign in attempt in progress`,
              blocked: `<strong>{{subject}}</strong> sign in attempt was blocked`,
            },
            PasswordChange: {
              inprogress: `<strong>{{subject}}</strong> had password reset requested`,
              // not sure these happen but just in case
              pass: `<strong>{{subject}}</strong> password reset request successful`,
              fail: `<strong>{{subject}}</strong> password reset request unsuccessful`,
            },
            ForgotPassword: {
              pass: `<strong>{{subject}}</strong> successfully changed password`,
              fail: `<strong>{{subject}}</strong> unsuccessfully tried to change password`,
              // not sure this one happens but just in case
              inprogress: `<strong>{{subject}}</strong> password change in progress`,
            },
          },
        },
        auditv2: {
          title: "Audit Log",
          action: "Search",
          byDetail: "By detail",
          eventTypeLabel: "Event Type",
          eventType: {
            user: "User",
            userRole: "User Role",
            userArchiveRecord: "User Archive Record",
            note: "Participant Note",
            role: "Role",
          },
          userEmailLabel: "User Email",
          byDate: "By date",
          datePickerFromLabel: "From",
          datePickerToLabel: "To",
          resultsHeading:
            "Search results (Page {{currentPage}} of {{totalPages}})",
          noResultsHeading: "No results",
          timestampHeader: "Timestamp",
          eventHeader: "Event",
          userHeader: "By",
        },
      },
      footer: {
        contactUs: "Contact us",
        copyright: "{{year}} GRAIL Bio UK Ltd.",
      },
    },
  },
};
