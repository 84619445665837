import { AxiosInstance } from "axios";
import { BiologicalSex } from "enums";
import { DateTime } from "luxon";
import {
  IAddress,
  IParticipatingHistory,
  IGp,
  IPerson,
  IPersonDeceasedStatus,
  ISearchResult,
  IParticipatingStatusUpdate,
} from "interfaces";
import { formatAPIDate } from "formatters";
import { isInputDigitsAndSpacesOnly, nhsNumberInputToString } from "helpers";
import { Channel, ICommunication } from "interfaces/ICommunication";
import {
  ISelfReportedCancerHistory,
  ISelfReportedCancerStatusUpdate,
} from "interfaces/ISelfReportedCancerStatus";

export type ContactStatus = {
  canEmail: boolean;
  canSMS: boolean;
};

export interface APIParticipationHistory {
  participatingInBloodDraws: boolean;
  timestamp: string;
  username: string;
  reason: string;
}

export const apiParticipationHistoryToParticipationHistory = (
  input: APIParticipationHistory[]
): IParticipatingHistory => {
  const hist: IParticipatingHistory = [];
  for (const status of input) {
    const timestamp = DateTime.fromISO(status.timestamp);
    hist.push({
      participatingInBloodDraws: status.participatingInBloodDraws,
      timestamp,
      username: status.username,
      reason: status.reason,
    });
  }
  return hist;
};

export interface APISelfReportedCancerStatus {
  selfReportedCancer: boolean;
  timestamp: string;
  username: string;
}

export const apiSelfReportedCancerHistoryToInternal = (
  input: APISelfReportedCancerStatus[]
): ISelfReportedCancerHistory => {
  const hist: ISelfReportedCancerHistory = [];
  for (const status of input) {
    const timestamp = DateTime.fromISO(status.timestamp);
    hist.push({
      selfReportedCancer: status.selfReportedCancer,
      timestamp,
      username: status.username,
    });
  }
  return hist;
};

export interface APIPerson {
  firstName: string;
  lastName: string;
  cohortId: string;
  withdrawn: boolean;
  nhsNumber: string;
  dateOfBirth: string;
  biologicalSex: string;
  mailingAddress: IAddress;
  gpPractice: IGp;
  gpName: string;
  phoneNumber: string;

  mobilePhoneNumber?: string | null;
  email?: string;
  dataSharingAgreementVersion?: string;
  isEligible?: boolean;
  outsideOfEngland: boolean;
  excluded: boolean;
  deceased: boolean;
}

export interface APISearchResult {
  firstName: string;
  lastName: string;
  cohortId: string;
  nhsNumber: string;
  dateOfBirth: string;
  mailingAddress: IAddress;
}

interface APICommunication {
  channel: Channel;
  type: string;
  sentAt: string;
}

export const apiPersonToPerson = (input: APIPerson): IPerson => {
  const { dateOfBirth, biologicalSex, mobilePhoneNumber } = input;
  return {
    ...input,
    dateOfBirth: DateTime.fromISO(dateOfBirth, { zone: "utc" }),
    biologicalSex: BiologicalSex[biologicalSex as keyof typeof BiologicalSex],
    mobilePhoneNumber: mobilePhoneNumber || "",
  };
};

interface PartialAddresses {
  mailingAddress?: Partial<IAddress>;
}

interface PartialGp extends Partial<IGp> {
  gpAddress?: Partial<IAddress>;
}

export type UpdatePayload = Partial<IPerson> & PartialAddresses & PartialGp;

export interface UpdateResponse {
  cohortId: string;
}

export type UpdateAsParticipantPayload = Partial<
  Pick<
    IPerson,
    | "cohortId"
    | "firstName"
    | "lastName"
    | "biologicalSex"
    | "phoneNumber"
    | "mobilePhoneNumber"
    | "email"
    | "mailingAddress"
    | "gpPractice"
  >
>;

export interface IPeopleClient {
  getPerson: (cohortId: string) => Promise<IPerson>;
  updatePerson: (
    cohortId: string,
    payload: UpdatePayload
  ) => Promise<UpdateResponse>;
  getContactStatus: (cohortId: string) => Promise<ContactStatus>;
  setContactStatus: (cohortId: string, status: ContactStatus) => Promise<void>;
  getSelfReportedCancerHistory: (
    cohortId: string
  ) => Promise<ISelfReportedCancerHistory>;
  setSelfReportedCancerStatus: (
    cohortId: string,
    status: ISelfReportedCancerStatusUpdate
  ) => Promise<void>;
  getParticipatingHistory: (cohortId: string) => Promise<IParticipatingHistory>;
  setParticipatingInBloodDraws: (
    cohortId: string,
    status: IParticipatingStatusUpdate
  ) => Promise<void>;
  getPeople: (
    q: string,
    field: string,
    limit: number
  ) => Promise<ISearchResult[]>;
  getPersonForNextEvent: (
    nhsNumber: string,
    dateOfBirth: DateTime,
    lastName: string
  ) => Promise<IPerson>;
  updatePersonAsParticipant: (
    payload: UpdateAsParticipantPayload
  ) => Promise<void>;
  removeNCRASStatus: (cohortId: string, reason?: string) => Promise<void>;
  getPersonDeceasedStatus: (cohortId: string) => Promise<IPersonDeceasedStatus>;
  getPersonCommunications: (cohortId: string) => Promise<ICommunication[]>;
}

export class PeopleClient implements IPeopleClient {
  public constructor(protected readonly http: AxiosInstance) {}

  public async getPerson(cohortId: string): Promise<IPerson> {
    const { data } = await this.http.get(`/people/${cohortId}`);
    return apiPersonToPerson(data);
  }

  public async getContactStatus(cohortId: string): Promise<ContactStatus> {
    const { data } = await this.http.get(`/people/${cohortId}/contactStatus`);
    return data;
  }

  public async setContactStatus(
    cohortId: string,
    status: ContactStatus
  ): Promise<void> {
    await this.http.post(`/people/${cohortId}/contactStatus`, status);
  }

  public async getSelfReportedCancerHistory(
    cohortId: string
  ): Promise<ISelfReportedCancerHistory> {
    return this.http
      .get(`/people/${cohortId}/selfReportedCancer`)
      .then((response) =>
        apiSelfReportedCancerHistoryToInternal(response.data)
      );
  }

  public async setSelfReportedCancerStatus(
    cohortId: string,
    status: ISelfReportedCancerStatusUpdate
  ) {
    await this.http.post(`/people/${cohortId}/selfReportedCancer`, status);
  }

  public async getParticipatingHistory(
    cohortId: string
  ): Promise<IParticipatingHistory> {
    return this.http
      .get(`/people/${cohortId}/participatingHistory`)
      .then((response) =>
        apiParticipationHistoryToParticipationHistory(response.data)
      );
  }

  public async setParticipatingInBloodDraws(
    cohortId: string,
    status: IParticipatingStatusUpdate
  ): Promise<void> {
    await this.http.post(`/people/${cohortId}/participatingHistory`, status);
  }

  public async updatePerson(
    cohortId: string,
    payload: UpdatePayload
  ): Promise<UpdateResponse> {
    const formattedPayload = payload.dateOfBirth
      ? {
          ...payload,
          dateOfBirth: formatAPIDate(payload.dateOfBirth),
        }
      : payload;

    const { data } = await this.http.put(
      `/people/${cohortId}`,
      formattedPayload
    );

    return data;
  }

  public async getPeople(
    q: string,
    field: string,
    limit: number
  ): Promise<ISearchResult[]> {
    if (isInputDigitsAndSpacesOnly(q)) {
      q = nhsNumberInputToString(q);
    }
    const { data } = await this.http.get(`/people`, {
      params: {
        q,
        field,
        limit,
      },
    });

    return data.map((input: APISearchResult) => ({
      ...input,
      dateOfBirth: DateTime.fromISO(input.dateOfBirth, { zone: "utc" }),
    }));
  }

  public async getPersonForNextEvent(
    nhsNumber: string,
    dateOfBirth: DateTime,
    lastName: string
  ): Promise<IPerson> {
    const { data } = await this.http.get(`/person`, {
      params: {
        nhsNumber,
        dateOfBirth: dateOfBirth.toISODate(),
        lastName,
      },
    });

    return apiPersonToPerson(data);
  }

  public async updatePersonAsParticipant(
    payload: UpdateAsParticipantPayload
  ): Promise<void> {
    await this.http.patch(`/participant-details`, payload);
  }

  public async removeNCRASStatus(
    cohortId: string,
    reason?: string
  ): Promise<void> {
    await this.http.delete(`/exclusions/${cohortId}`, { data: { reason } });
  }

  public async getPersonDeceasedStatus(
    cohortId: string
  ): Promise<IPersonDeceasedStatus> {
    const response = await this.http.get(`/people/${cohortId}/deceasedStatus`);
    const { timestamp } = response.data;
    return {
      ...response.data,
      timestamp: DateTime.fromISO(timestamp),
    };
  }

  public async getPersonCommunications(
    cohortId: string
  ): Promise<ICommunication[]> {
    const response = await this.http.get(`/people/${cohortId}/communications`);
    return response.data.map((communication: APICommunication) => ({
      ...communication,
      sentAt: DateTime.fromISO(communication.sentAt),
    }));
  }
}
